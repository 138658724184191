import React from 'react';
import Alina from '../images/Alina.JPG';
import Chingiz from '../images/Chingiz.jpg';
import Peter from '../images/Peter.jpeg';
import Emily from '../images/Emily.jpg';

const Team = () => {

    return (
        <div id='team'>
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl uppercase font-bold" style={{color: '#FF66C4'}}>Our
                        Team</h2>
                    <div className='flex justify-center'>
                        <div className='w-24' style={{borderBottom: '4px solid #FF66C4'}}></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold"
                        style={{color: '#86B154'}}>We bring together a diverse team of interdisciplinary experts.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="10">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        {/* Emily Kieson */}
                        <div
                            className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img"
                                     className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                     src={Emily} style={{width: '100%', height: '250px', objectFit: 'cover'}}/>
                                <h2 className="font-semibold my-4 text-2xl text-center">Emily Kieson</h2>
                                <h2 className="font-semibold my-4 text-2xl text-center"
                                    style={{color: '#86B154'}}>Co-Founder</h2>
                                <div className="flex justify-center my-4">
                                    <a href="https://www.linkedin.com/in/emilykieson/"
                                       className="hover:text-gray-500 transition duration-150 ease-in-out"
                                       aria-label="LinkedIn">
                                        <svg className="w-10 h-10 fill-current" viewBox="0 0 50 50"
                                             xmlns="http://www.w3.org/2000/svg" style={{color: '#FF66C4'}}>
                                            <path
                                                d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 14 11.011719 C 12.904779 11.011719 11.919219 11.339079 11.189453 11.953125 C 10.459687 12.567171 10.011719 13.484511 10.011719 14.466797 C 10.011719 16.333977 11.631285 17.789609 13.691406 17.933594 A 0.98809878 0.98809878 0 0 0 13.695312 17.935547 A 0.98809878 0.98809878 0 0 0 14 17.988281 C 16.27301 17.988281 17.988281 16.396083 17.988281 14.466797 A 0.98809878 0.98809878 0 0 0 17.986328 14.414062 C 17.884577 12.513831 16.190443 11.011719 14 11.011719 z M 14 12.988281 C 15.392231 12.988281 15.94197 13.610038 16.001953 14.492188 C 15.989803 15.348434 15.460091 16.011719 14 16.011719 C 12.614594 16.011719 11.988281 15.302225 11.988281 14.466797 C 11.988281 14.049083 12.140703 13.734298 12.460938 13.464844 C 12.78117 13.19539 13.295221 12.988281 14 12.988281 z M 11 19 A 1.0001 1.0001 0 0 0 10 20 L 10 39 A 1.0001 1.0001 0 0 0 11 40 L 17 40 A 1.0001 1.0001 0 0 0 18 39 L 18 33.134766 L 18 20 A 1.0001 1.0001 0 0 0 17 19 L 11 19 z M 20 19 A 1.0001 1.0001 0 0 0 19 20 L 19 39 A 1.0001 1.0001 0 0 0 20 40 L 26 40 A 1.0001 1.0001 0 0 0 27 39 L 27 29 C 27 28.170333 27.226394 27.345035 27.625 26.804688 C 28.023606 26.264339 28.526466 25.940057 29.482422 25.957031 C 30.468166 25.973981 30.989999 26.311669 31.384766 26.841797 C 31.779532 27.371924 32 28.166667 32 29 L 32 39 A 1.0001 1.0001 0 0 0 33 40 L 39 40 A 1.0001 1.0001 0 0 0 40 39 L 40 28.261719 C 40 25.300181 39.122788 22.95433 37.619141 21.367188 C 36.115493 19.780044 34.024172 19 31.8125 19 C 29.710483 19 28.110853 19.704889 27 20.423828 L 27 20 A 1.0001 1.0001 0 0 0 26 19 L 20 19 z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Peter Gloor */}
                        <div
                            className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img"
                                     className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                     src={Peter} style={{width: '100%', height: '250px', objectFit: 'cover'}}/>
                                <h2 className="font-semibold my-4 text-2xl text-center">Peter Gloor</h2>
                                <h2 className="font-semibold my-4 text-2xl text-center"
                                    style={{color: '#86B154'}}>Co-Founder</h2>
                                <div className="flex justify-center my-4">
                                    <a href="https://www.linkedin.com/in/petergloor/"
                                       className="hover:text-gray-500 transition duration-150 ease-in-out"
                                       aria-label="LinkedIn">
                                        <svg className="w-10 h-10 fill-current" viewBox="0 0 50 50"
                                             xmlns="http://www.w3.org/2000/svg" style={{color: '#FF66C4'}}>
                                            <path
                                                d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 14 11.011719 C 12.904779 11.011719 11.919219 11.339079 11.189453 11.953125 C 10.459687 12.567171 10.011719 13.484511 10.011719 14.466797 C 10.011719 16.333977 11.631285 17.789609 13.691406 17.933594 A 0.98809878 0.98809878 0 0 0 13.695312 17.935547 A 0.98809878 0.98809878 0 0 0 14 17.988281 C 16.27301 17.988281 17.988281 16.396083 17.988281 14.466797 A 0.98809878 0.98809878 0 0 0 17.986328 14.414062 C 17.884577 12.513831 16.190443 11.011719 14 11.011719 z M 14 12.988281 C 15.392231 12.988281 15.94197 13.610038 16.001953 14.492188 C 15.989803 15.348434 15.460091 16.011719 14 16.011719 C 12.614594 16.011719 11.988281 15.302225 11.988281 14.466797 C 11.988281 14.049083 12.140703 13.734298 12.460938 13.464844 C 12.78117 13.19539 13.295221 12.988281 14 12.988281 z M 11 19 A 1.0001 1.0001 0 0 0 10 20 L 10 39 A 1.0001 1.0001 0 0 0 11 40 L 17 40 A 1.0001 1.0001 0 0 0 18 39 L 18 33.134766 L 18 20 A 1.0001 1.0001 0 0 0 17 19 L 11 19 z M 20 19 A 1.0001 1.0001 0 0 0 19 20 L 19 39 A 1.0001 1.0001 0 0 0 20 40 L 26 40 A 1.0001 1.0001 0 0 0 27 39 L 27 29 C 27 28.170333 27.226394 27.345035 27.625 26.804688 C 28.023606 26.264339 28.526466 25.940057 29.482422 25.957031 C 30.468166 25.973981 30.989999 26.311669 31.384766 26.841797 C 31.779532 27.371924 32 28.166667 32 29 L 32 39 A 1.0001 1.0001 0 0 0 33 40 L 39 40 A 1.0001 1.0001 0 0 0 40 39 L 40 28.261719 C 40 25.300181 39.122788 22.95433 37.619141 21.367188 C 36.115493 19.780044 34.024172 19 31.8125 19 C 29.710483 19 28.110853 19.704889 27 20.423828 L 27 20 A 1.0001 1.0001 0 0 0 26 19 L 20 19 z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Alina Hafner */}
                        <div
                            className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img"
                                     className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                     src={Alina} style={{width: '100%', height: '250px', objectFit: 'cover'}}/>
                                <h2 className="font-semibold my-4 text-2xl text-center">Alina Hafner</h2>
                                <h2 className="font-semibold my-4 text-2xl text-center"
                                    style={{color: '#86B154'}}>Co-Founder</h2>
                                <div className="flex justify-center my-4">
                                    <a href="https://www.linkedin.com/in/alina-hafner/"
                                       className="hover:text-gray-500 transition duration-150 ease-in-out"
                                       aria-label="LinkedIn">
                                        <svg className="w-10 h-10 fill-current" viewBox="0 0 50 50"
                                             xmlns="http://www.w3.org/2000/svg" style={{color: '#FF66C4'}}>
                                            <path
                                                d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 14 11.011719 C 12.904779 11.011719 11.919219 11.339079 11.189453 11.953125 C 10.459687 12.567171 10.011719 13.484511 10.011719 14.466797 C 10.011719 16.333977 11.631285 17.789609 13.691406 17.933594 A 0.98809878 0.98809878 0 0 0 13.695312 17.935547 A 0.98809878 0.98809878 0 0 0 14 17.988281 C 16.27301 17.988281 17.988281 16.396083 17.988281 14.466797 A 0.98809878 0.98809878 0 0 0 17.986328 14.414062 C 17.884577 12.513831 16.190443 11.011719 14 11.011719 z M 14 12.988281 C 15.392231 12.988281 15.94197 13.610038 16.001953 14.492188 C 15.989803 15.348434 15.460091 16.011719 14 16.011719 C 12.614594 16.011719 11.988281 15.302225 11.988281 14.466797 C 11.988281 14.049083 12.140703 13.734298 12.460938 13.464844 C 12.78117 13.19539 13.295221 12.988281 14 12.988281 z M 11 19 A 1.0001 1.0001 0 0 0 10 20 L 10 39 A 1.0001 1.0001 0 0 0 11 40 L 17 40 A 1.0001 1.0001 0 0 0 18 39 L 18 33.134766 L 18 20 A 1.0001 1.0001 0 0 0 17 19 L 11 19 z M 20 19 A 1.0001 1.0001 0 0 0 19 20 L 19 39 A 1.0001 1.0001 0 0 0 20 40 L 26 40 A 1.0001 1.0001 0 0 0 27 39 L 27 29 C 27 28.170333 27.226394 27.345035 27.625 26.804688 C 28.023606 26.264339 28.526466 25.940057 29.482422 25.957031 C 30.468166 25.973981 30.989999 26.311669 31.384766 26.841797 C 31.779532 27.371924 32 28.166667 32 29 L 32 39 A 1.0001 1.0001 0 0 0 33 40 L 39 40 A 1.0001 1.0001 0 0 0 40 39 L 40 28.261719 C 40 25.300181 39.122788 22.95433 37.619141 21.367188 C 36.115493 19.780044 34.024172 19 31.8125 19 C 29.710483 19 28.110853 19.704889 27 20.423828 L 27 20 A 1.0001 1.0001 0 0 0 26 19 L 20 19 z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Chingiz Kuanyshbay */}
                        <div
                            className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img"
                                     className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                                     src={Chingiz} style={{width: '100%', height: '250px', objectFit: 'cover'}}/>
                                <h2 className="font-semibold my-4 text-2xl text-center">Chingiz Kuanyshbay</h2>
                                <h2 className="font-semibold my-4 text-2xl text-center"
                                    style={{color: '#86B154'}}>ML/CV Engineer</h2>
                                <div className="flex justify-center my-4">
                                    <a href="https://www.linkedin.com/in/chingiz-kuanyshbay-20550925b/"
                                       className="hover:text-gray-500 transition duration-150 ease-in-out"
                                       aria-label="LinkedIn">
                                        <svg className="w-10 h-10 fill-current" viewBox="0 0 50 50"
                                             xmlns="http://www.w3.org/2000/svg" style={{color: '#FF66C4'}}>
                                            <path
                                                d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 14 11.011719 C 12.904779 11.011719 11.919219 11.339079 11.189453 11.953125 C 10.459687 12.567171 10.011719 13.484511 10.011719 14.466797 C 10.011719 16.333977 11.631285 17.789609 13.691406 17.933594 A 0.98809878 0.98809878 0 0 0 13.695312 17.935547 A 0.98809878 0.98809878 0 0 0 14 17.988281 C 16.27301 17.988281 17.988281 16.396083 17.988281 14.466797 A 0.98809878 0.98809878 0 0 0 17.986328 14.414062 C 17.884577 12.513831 16.190443 11.011719 14 11.011719 z M 14 12.988281 C 15.392231 12.988281 15.94197 13.610038 16.001953 14.492188 C 15.989803 15.348434 15.460091 16.011719 14 16.011719 C 12.614594 16.011719 11.988281 15.302225 11.988281 14.466797 C 11.988281 14.049083 12.140703 13.734298 12.460938 13.464844 C 12.78117 13.19539 13.295221 12.988281 14 12.988281 z M 11 19 A 1.0001 1.0001 0 0 0 10 20 L 10 39 A 1.0001 1.0001 0 0 0 11 40 L 17 40 A 1.0001 1.0001 0 0 0 18 39 L 18 33.134766 L 18 20 A 1.0001 1.0001 0 0 0 17 19 L 11 19 z M 20 19 A 1.0001 1.0001 0 0 0 19 20 L 19 39 A 1.0001 1.0001 0 0 0 20 40 L 26 40 A 1.0001 1.0001 0 0 0 27 39 L 27 29 C 27 28.170333 27.226394 27.345035 27.625 26.804688 C 28.023606 26.264339 28.526466 25.940057 29.482422 25.957031 C 30.468166 25.973981 30.989999 26.311669 31.384766 26.841797 C 31.779532 27.371924 32 28.166667 32 29 L 32 39 A 1.0001 1.0001 0 0 0 33 40 L 39 40 A 1.0001 1.0001 0 0 0 40 39 L 40 28.261719 C 40 25.300181 39.122788 22.95433 37.619141 21.367188 C 36.115493 19.780044 34.024172 19 31.8125 19 C 29.710483 19 28.110853 19.704889 27 20.423828 L 27 20 A 1.0001 1.0001 0 0 0 26 19 L 20 19 z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
                         data-aos="zoom-out">
                        <div className="lg:w-2/5 flex flex-col lg:mx-4 justify-center">
                            <div className="mb-4" style={{color: '#FF66C4'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24"
                                     className="fill-current">
                                    <path
                                        d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                                    <path
                                        d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl font-bold" style={{color: '#FF66C4'}}>We <span
                                className="font-black">Research</span></h3>
                            <div className="flex-grow">
                                <p className="my-3 text-xl lg:text-align:left text-gray-600 font-semibold">
                                    Our results are based on over 5 years of academic research at top research
                                    institutions (MIT, University of Cologne, TUM, University of Bamberg, HSLU).
                                </p>
                                <p className="my-3 text-xl lg:text-align:left text-gray-600 font-semibold">
                                    We regularly publish our results in academic outlets and visit conferences around
                                    the research discipline of animal-computer interaction (ACI).
                                </p>
                                <a href="https://www.dropbox.com/scl/fo/97b8nn8qm82r9ed40gebi/AJwkdmhx1J1I-Y3mMPr0aqM?rlkey=l02cslaaxuti4m1r2hanxjzy4&st=02qcui0v&dl=0" target="_blank"
                                   rel="noopener noreferrer"
                                   className="text-white bg-[#FF66C4] hover:bg-[#FF66C4] inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl">
                                    Explore our research
                                </a>
                            </div>
                        </div>
                        <div className="lg:w-2/5 flex flex-col lg:mx-4 justify-center">
                            <div className="mb-4" style={{color: '#FF66C4'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24"
                                     className="fill-current">
                                    <path
                                        d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl font-bold" style={{color: '#FF66C4'}}>We <span
                                className="font-black">Collaborate</span></h3>
                            <div className="flex-grow">
                                <p className="my-3 text-xl lg:text-align:left text-gray-600 font-semibold">
                                    We frequently collaborate with animal shelters, journalists, and other start-ups.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Team;
